<template>
  <div>
    <div v-if="value === 'passed'" class="is-flex align-center">
      <icon icon="circle" class="has-text-success tooltip"
        :data-tooltip="`The Brake Test PASSED at ${reading.read_at}`"/>
      Brake Test Passed
    </div>
    <div v-if="value === 'failed'" class="is-flex align-center">
      <icon icon="circle" class="has-text-danger tooltip"
        :data-tooltip="`The Brake Test FAILED at ${reading.read_at}`"/>
      Brake Test Failed
    </div>
    <div v-if="value === 'pending'" class="is-flex align-center">
      <icon icon="exclamation-triangle" class="has-text-warning tooltip"
        :data-tooltip="`The Brake Test is PENDING at ${reading.read_at}`"/>
      Brake Test Overdue
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reading: {
      type: Object,
      required: true,
    },
    variableName: {
      type: String,
      required: true,
    },
  },
  computed: {
    value() {
      return this.reading.data[this.variableName]
    },
  },
}
</script>

<style scoped>

</style>