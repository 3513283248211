<template>
  <div v-if="typeof value === 'boolean'">
    <div v-show="value">
      <div class="is-flex align-center">
        <icon icon="cogs" class="has-text-danger tooltip"
          :data-tooltip="`The machine's fault status as at ${reading.read_at}`"/>
        Fault
      </div>
    </div>
    <div v-show="!value">
      <div class="is-flex align-center">
        <icon icon="circle" class="has-text-success tooltip"
          :data-tooltip="`The machine's fault status as at ${reading.read_at}`"/>
        Stable
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reading: {
      type: Object,
      required: true,
    },
    variableName: {
      type: String,
      required: true,
    },
  },
  computed: {
    value() {
      return this.reading.data[this.variableName]
    },
  },
}
</script>

<style scoped>

</style>