<template>
  <div class="iot-widget-wrapper">
    <h5 class="title is-4 is-flex align-center has-text-weight-light">
      <icon class="mr has-text-grey" icon="tint"/>
      <span>Device Humidity</span>
    </h5>

    <div class="columns">
      <div class="column">
        <span class="is-flex align-center">
          <span class="is-size-3 has-text-weight-bold has-text-grey">
            {{ humidity }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { first, forEach, reverse, sortBy } from 'lodash'

export default {

  props: {
    latestReading: {
      type: Object,
      required: true,
    },
    oldestReading: {
      type: Object,
      required: true,
    },
    last24HourReadings: {
      type: Array,
      default: [],
      required: true,
    },
    variableName: {
      type: String,
      required: true,
    },
  },

  computed: {
    humidity() {
      return this.latestReading.data[this.variableName].toFixed()
    },
  },

}
</script>