import { computed } from './mixin-builder'

/**
 * Determines if an error is present. An error may either be
 * an Array or String. If it is an array, the FieldError
 * component will select the first error in it.
 */
export let hasError = computed.make({
  hasError() {
    return Boolean(this.error) && (
      this.error instanceof Array && this.error.length
      || typeof this.error === "string"
    )
  }
})
