<template>
  <div id="chart">
    <apexchart
      type="donut"
      v-bind="{ height, options, series }" />
  </div>
</template>

<script>
export default {

  props: {
    data: {
      type: Object,
      required: true,
    },
    height: {
      type: String,
      default: '350'
    },
    legendPosition: {
      type: String,
      default: 'right'
    }
  },

  computed: {
    options() {
      return {
        labels: this.data.labels,
        colors: process.env.VUE_APP_ASSET_DONUT_COLORS.split(','),
        legend: {
          position: this.legendPosition
        },
        dataLabels: {
          dropShadow: {
            enabled: false,
          }
        },
        chart: {
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: true,
            easing: 'easeout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 0.75,
            }
          },
        },
      }
    },

    series() {
      return this.data.series
    }
  }

}
</script>
