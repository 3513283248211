import { Bar } from 'vue-chartjs'

export default {

  extends: Bar,

  props: {
    data: Object,
    options: Object,
    responsive: {
      type: Boolean,
      default: true,
    },
    maintainAspect: {
      type: Boolean,
      default: true,
    }
  },

  mounted() {
    let { data, options } = this
    this.renderChart(data, {
      ...options,
      responsive: this.responsive,
      maintainAspectRatio: this.maintainAspect,
    })
  },

  data() {
    return {
      maintainAspectRatio: this.maintainAspect
    }
  }

}
