<template>
  <div class="iot-widget-wrapper">
    <h5 class="title is-4 is-flex align-center has-text-weight-light">
      <icon class="mr has-text-grey" icon="hourglass-half"/>
      <span>Run Time</span>
    </h5>

    <div class="columns">
      <div class="column">
        <span class="has-text-weight-bold">
            {{ startRunTime }} km
          </span>
        <br>
        <span>
            Start
          </span>
      </div>
      <div class="column">
        <span class="has-text-weight-bold">
            {{ currentRunTime }} km
          </span>
        <br>
        <span>
            Current
          </span>
      </div>
    </div>
  </div>
</template>
<script>
import { first, forEach, reverse, sortBy } from 'lodash'

export default {

  props: {
    latestReading: {
      type: Object,
      required: true,
    },
    oldestReading: {
      type: Object,
      required: true,
    },
    last24HourReadings: {
      type: Array,
      default: [],
      required: true,
    },
    variableName: {
      type: String,
      required: true,
    },
  },

  computed: {
    startRunTime() {
      return this.oldestReading.data[this.variableName].toLocaleString()
    },
    currentRunTime() {
      return this.latestReading.data[this.variableName].toLocaleString()
    },
  },

}
</script>