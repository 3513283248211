<template>
  <div>
    <div v-show="value">
      <icon icon="plug" class="has-text-grey"/>
      {{ value }} mV
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reading: {
      type: Object,
      required: true,
    },
    variableName: {
      type: String,
      required: true,
    },
  },

  computed: {
    value() {
      return this.reading.data[this.variableName]
    },
  },
}
</script>

<style scoped>

</style>