<template>
  <aside class="sidebar-content">
    <nav-route exact name="dashboard" icon="home" class="has-text-weight-semibold">{{ Naming.Overview }}</nav-route>
    <nav-route :to="{ name: 'job-index' }" icon="tags">{{ Naming.Jobs }}</nav-route>
    <nav-route :to="{ name: 'site-admin-index' }" icon="city">{{ Naming.Sites }}</nav-route>
    <nav-route exact name="asset-admin-dashboard">{{ Naming.AssetDashboard }}</nav-route>
  </aside>
</template>
<script>
import NavRoute from '../NavRoute'

export default {
  components: {
    NavRoute
  }
}
</script>
