<template>
  <router-link
    v-bind="{ exact, to: to || { name } }"
    @click.native="$root.app.closeSidebar">
    <icon class="mr-1" v-bind="{ icon, iconType }"/>
    <slot/>
  </router-link>
</template>

<script>
export default {

  props: {
    exact: Boolean,
    to: Object,
    name: String,
    icon: String,
    iconType: String,
  }

}
</script>
