<template>
  <aside class="app-sidebar" :class="active">
    <div class="logo" :class="{ ' white-background': whiteBackground }">
      <img :src="logoUrl">
    </div>
    <component v-prevent-parent-scroll v-if="role" :is="'side-bar-' + role.name"/>
    <div class="auth-user">
      <span class="pointer" @click="goToProfile()">
        <avatar :size="34" :path="user.profile.picture_url" :name="user.full_name"/>
      </span>
      <div class="user-details">
        <p class="has-text-weight-light">{{ userName }}</p>
      </div>
      <a @click="logout" class="logout has-tooltip-top has-tooltip-signout-tooltip" data-tooltip="Sign out">
        <icon icon="sign-out-alt"/>
      </a>
    </div>
  </aside>
</template>

<script>

import { mapState, mapGetters } from 'vuex'

export default {

  props: ['active'],

  data: () => ({
    base: process.env.VUE_APP_BASE_URL
  }),

  computed: {
    ...mapState('company', [
      'company'
    ]),
    ...mapGetters('auth', [
      'role',
      'user'
    ]),
    logoUrl() {
      return this.company.branding && this.company.branding.app_logo_url ? this.company.branding.app_logo_url : process.env.VUE_APP_NAV_LOGO
    },
    whiteBackground() {
      return this.$store.state.company.company.white_logo_background
    },
    userName(){
      const maxLength = 15;
      if (this.user.name.length > maxLength) {
        return this.user.name.substring(0, maxLength) + '...';
      }
      return this.user.name;
    }
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$toast.log(this.$lang.auth.bye)
        this.$router.push({ name: 'signin' })
      })
    },
    goToProfile() {
      this.$router.push({
        name: 'user-profile'
      })
    }
  }

}
</script>
