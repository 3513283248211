<template>
  <loader v-bind="{ loading }">
    <div id="chart">
      <p class="has-text-black-ter is-flex align-center justify-between">
        <span>{{ Naming.Inspections }} performed in the last 6 months</span>
        <route name="inspection-index" class="has-text-info">All {{ Naming.Inspections }} &rarr;</route>
      </p>
      <apexchart
        type=line
        height=300
        v-bind="{ options, series }"/>
    </div>
  </loader>
</template>

<script>
import { dashboard as backend } from '@/api'

export default {

  data: () => ({
    loaded: false,
    loading: true,
    inspections: {}
  }),

  created() {
    this.loading = true
    backend.inspectionsOverTime(response => {
      this.inspections = response.data
    }).then(() => {
      this.loaded = true
      this.loading = false
    })
  },

  computed: {
    options() {
      return {
        chart: {
          id: '6-month-inspection-stats',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          }
        },
        colors: [process.env.VUE_APP_GRAPH_COLOR,],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            shadeIntensity: 0.4,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          },
        },
        xaxis: {
          categories: Object.keys(this.inspections)
        },
        stroke: {
          curve: 'smooth',
        },
      }
    },
    series() {
      return [{
        name: 'Inspections',
        data: Object.values(this.inspections)
      }]
    }
  }
}
</script>
