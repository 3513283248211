<template>
  <div class="iot-widget-wrapper">
    <h5 class="title is-4 is-flex align-center has-text-weight-light">
      <icon class="mr has-text-grey" icon="fire"/>
      <span>Machine Overload</span>
    </h5>

    <div class="columns">
      <div class="column">
        <span class="is-flex align-center" v-if="isOverloaded">
          <icon class="has-text-danger iginition-status-icon" icon="exclamation-circle"/>
          <span class="has-text-weight-bold">
            The machine is currently overloaded.
            <br>
            <span
              :data-tooltip="`The machine is overloaded as at the latest reading, at ${latestReading.read_at}`"
              class="is-size-7 has-text-grey tooltip"
            >{{ latestReading.read_at | date('L LTS') }}</span>
          </span>
        </span>
        <span class="is-flex align-center" v-if="!isOverloaded">
          <icon class="has-text-success iginition-status-icon" icon="circle"/>
          <span class="has-text-weight-bold">
            The machine is currently stable.
            <br>
            <span
              :data-tooltip="`The machine is stable as at the latest reading, at ${latestReading.read_at}`"
              class="is-size-7 has-text-grey tooltip"
            >{{ latestReading.read_at | date('L LTS') }}</span>
          </span>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { first, forEach, reverse, sortBy } from 'lodash'

export default {

  props: {
    latestReading: {
      type: Object,
      required: true,
    },
    oldestReading: {
      type: Object,
      required: true,
    },
    last24HourReadings: {
      type: Array,
      default: [],
      required: true,
    },
    variableName: {
      type: String,
      required: true,
    },
  },

  computed: {
    isOverloaded() {
      return this.latestReading.data[this.variableName]
    },
  },

}
</script>