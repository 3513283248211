<template>
  <div class="nav-group">
    <span class="nav-group-header">
      <icon class="mr-1" v-bind="{ icon, iconType }"/>
      <span class="group-title">{{ title }}</span>
    </span>
    <slot/>
  </div>
</template>

<script>
export default {

  props: {
    icon: String,
    iconType: String,
    title: String,
  }

}
</script>
