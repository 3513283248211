<template>
  <div class="is-flex align-center">
    <icon icon="thermometer-quarter" class="has-text-grey tooltip"
      :data-tooltip="`The device's temperature as at ${reading.read_at}`"/>
    {{ value }} °C
  </div>
</template>

<script>
export default {
  props: {
    reading: {
      type: Object,
      required: true,
    },
    variableName: {
      type: String,
      required: true,
    },
  },
  computed: {
    value() {
      return this.reading.data[this.variableName].toFixed()
    },
  },
}
</script>

<style scoped>

</style>