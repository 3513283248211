<template>
  <aside class="sidebar-content">
    <nav-route exact name="dashboard" icon="home" class="has-text-weight-semibold">{{ Naming.Overview }}</nav-route>
    <nav-route exact name="site-admin-index">{{ Naming.ClientSites }}</nav-route>
    <nav-route exact name="job-index">{{ Naming.AllJobs }}</nav-route>
    <nav-route exact name="asset-admin-dashboard">{{ Naming.AssetDashboard }}</nav-route>
  </aside>
</template>
<script>
import { mapGetters } from 'vuex'
import NavRoute from '../NavRoute'

export default {
  components: {
    NavRoute
  },
  computed: mapGetters('auth', ['user'])
}
</script>
