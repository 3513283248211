<template>
  <loader v-bind="{ loading }">
    <div id="top-techs-chart">
      <apexchart
        type="bar"
        height="350"
        v-bind="{ options, series }" />
    </div>
  </loader>
</template>

<script>
import { dashboard as backend } from '@/api'

export default {

  data: () => ({
    payload: [],
    loading: true
  }),

  beforeCreate() {
    backend.loadTopTechnicians(response => {
      this.payload = response.data
      this.loading = false
    })
  },

  computed: {
    options() {
      return {
        chart: {
          toolbar: {
            show: false,
          },
          id: 'Top Technicians'
        },
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            distributed: true
          }
        },
        theme: {
          monochrome: {
            enabled: true,
            color: process.env.VUE_APP_GRAPH_COLOR,
            shadeTo: 'light',
            shadeIntensity: 0.65
          }
        },
        xaxis: {
          categories: this.payload.map(entry => entry.user_name)
        }
      }
    },
    series() {
      return [{
        name: 'Inspections',
        data: this.payload.map(entry => entry.count)
      }]
    }
  }

}
</script>
