import { props } from './mixin-builder'
let { group, make } = props

/**
 * Commons
 */
const classTypes = Array|Boolean|Object|String

/**
 * Common props that are used in various components.
 * Some props are grouped as they're always used together.
 */

export let required = group({
  ...make('required', Boolean, false),
  ...make('requiredClass', String, 'has-text-danger'),
  ...make('requiredText', String, '*'),
})

export let error = group({
  ...make('error', Array|String, null),
  ...make('errorClass', String, 'is-danger'),
  ...make('errorSymbol'),
})

export let iconable = group({
  ...make('iconClass'),

  ...make('leftIcon'),
  ...make('leftIconType', String, 'fas'),
  ...make('leftIconPack', Boolean|String, false),

  ...make('rightIcon'),
  ...make('rightIconType', String, 'fas'),
  ...make('rightIconPack', Boolean|String, false),
})

export let items = group({
  ...make('items', Array, () => []),
  ...make('valueKey', Boolean|String, 'value'),
  ...make('labelKey', String, 'label'),
  ...make('slugValues', Boolean, false),
})

export let closeable = group({
  ...make('autoclose', Boolean, false),
  ...make('closeable', Boolean, false)
})

export let bounds = group({
  ...make('min', Number, Number.MIN_SAFE_INTEGER || -9007199254740991),
  ...make('max', Number, Number.MAX_SAFE_INTEGER || 9007199254740991),
})

export let dropable = group({
  ...make('hasDropdown', Boolean, false),
  ...make('dropdownIcon', Boolean|String, false),
  ...make('dropdownIconType', Boolean|String, false),
  ...make('dropdownIconPack', Boolean|String, false),
})

export let debouncable = group({
  ...make('debounce', Number, 0),
  ...make('debounceOptions', Object, () => ({})),
})

export let checked = group(
  make('checked', Boolean, false)
)

export let classes = group(
  make('classes', classTypes, false)
)

export let headerClass = group(
  make('headerClass', classTypes, false)
)

export let bodyClass = group(
  make('bodyClass', classTypes, false)
)

export let footerClass = group(
  make('footerClass', classTypes, false)
)

export let disabled = group(
  make('disabled', Boolean, false)
)

export let id = group(
  make('id')
)

export let innerLabel = group(
  make('innerLabel', String, '')
)

export let fieldId = group(
  make('fieldId')
)

export let fullWidth = group(
  make('fullWidth', Boolean, true)
)

export let label = group(
  make('label', Boolean, true)
)

export let name = group(
  make('name')
)

export let placeholder = group(
  make('placeholder')
)

export let precision = group(
  make('precision', Number, 2)
)

export let readonly = group(
  make('readonly', Boolean, false)
)

export let title = group(
  make('title', Boolean|String, false)
)

export let type = group(
  make('type')
)

export let working = group(
  make('working', Boolean, false)
)

export let autofocus = group(
  make('autofocus', Boolean, false)
)

export let dir = group(
  make('dir', undefined|String)
)

export let autocomplete = group(
  make('autocomplete', String, 'on')
)

export let role = group(
  make('role', Boolean|String, false)
)

export let describable = group({
  ...make('description', Boolean|String, false),
  ...make('descriptionClass', classTypes, false),
})

export let hintable = group({
  ...make('hint', [String, Boolean], false),
  ...make('hintClass', classTypes, false),
  ...make('hintIcon', undefined|String),
  ...make('hintIconPack', undefined|String),
  ...make('hintIconType', undefined|String),
})
