<template>
  <div class="iot-widget-wrapper">
    <h5 class="title is-4 is-flex align-center has-text-weight-light">
      <icon class="mr has-text-grey" icon="key"/>
      <span>Machine Ignition On</span>
    </h5>

    <div class="columns">
      <div class="column">
        <span class="is-flex align-center" v-if="ignitionIsOn">
          <icon class="has-text-success iginition-status-icon" icon="circle"/>
          <span class="has-text-weight-bold">
            Ignition is currently active
            <br>
            <span
              :data-tooltip="`The ignition was switched on at ${latestReading.read_at}`"
              class="is-size-7 has-text-grey tooltip"
            >{{ latestReading.read_at | date('L LTS') }}</span>
          </span>
        </span>
        <span class="is-flex align-center" v-if="ignitionIsOff">
          <icon class="has-text-danger iginition-status-icon" icon="power-off"/>
          <span class="has-text-weight-bold">
            Ignition is currently off
            <br>
            <span
              :data-tooltip="`The ignition was switched off at ${latestReading.read_at}`"
              class="is-size-7 has-text-grey tooltip"
            >{{ latestReading.read_at | date('L LTS') }}</span>
          </span>
        </span>
      </div>
      <div class="column has-text-right">
      <span class="is-size-7">
        The ignition has been cycled <span class="has-text-weight-bold">{{ powerCycles }}</span> times in the last 
        <span class="has-text-weight-bold">24 hours.</span>
      </span>
      </div>
    </div>
  </div>
</template>
<script>
import { first, forEach, reverse, sortBy } from 'lodash'

export default {

  props: {
    latestReading: {
      type: Object,
      required: true,
    },
    oldestReading: {
      type: Object,
      required: true,
    },
    last24HourReadings: {
      type: Array,
      default: [],
      required: true,
    },
    variableName: {
      type: String,
      required: true,
    },
  },

  computed: {
    ignitionIsOn() {
      return this.latestReading.data[this.variableName] === true
    },
    ignitionIsOff() {
      return this.ignitionIsOn === false
    },
    powerCycles() {
      let count = 0

      let lastReading = null
      forEach(this.last24HourReadings, reading => {
        if (reading.data[this.variableName] !== lastReading) {
          count++
        }

        lastReading = reading.data[this.variableName]
      })

      return count
    },
  },

}
</script>