<template>
<div class="summary-item" :class="{ box }">
  <span class="summary-title" :class="titleClass" v-if="title">
    <icon class="summary-icon" :class="iconClass" v-bind="{ icon, type: iconType }" v-if="icon"/>
    {{ title }}
  </span>
  <span class="summary-value" :class="[valueClass, { horizontal }]">
    {{ value }}
    <span class="summary-subvalue" :class="subvalue" v-if="subvalue">
      {{ subvalue }}
    </span>
  </span>
</div>
</template>

<script>
export default {

  props: {
    subvalue: String|Number,
    value: String|Number,
    title: String,
    box: Boolean,
    horizontal: Boolean,
    icon: {
      type: String,
      default: null
    },
    iconType: {
      type: String,
      default: 'fas'
    },
    valueClass: {
      type: String,
      default: ''
    },
    subvalue: {
      type: String,
      default: ''
    },
    titleClass: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: 'is-small'
    },
  }

}
</script>
