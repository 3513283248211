<template>
  <aside class="sidebar-content">
    <nav-route exact name="dashboard" icon="home" class="has-text-weight-semibold">{{ Naming.Overview }}</nav-route>

    <nav-group icon="briefcase" title="Activity">
      <nav-route exact name="job-calendar">{{ Naming.Calendar }}</nav-route>
      <nav-route exact name="job-index">{{ Naming.AllJobs }}</nav-route>
      <nav-route exact name="inspection-index">{{ Naming.Inspections }}</nav-route>
      <nav-route exact name="recurring-job-index">{{ Naming.RecurringJobs }}</nav-route>
      <nav-route exact name="asset-admin-dashboard">{{ Naming.AssetDashboard }}</nav-route>
    </nav-group>

    <nav-group icon="users" :title="Naming.Clients">
      <nav-route exact name="client-admin-index">{{ Naming.AllClients }}</nav-route>
      <nav-route exact name="site-admin-index">{{ Naming.ClientSites }}</nav-route>
    </nav-group>

    <nav-group icon="clipboard-list" :title="Naming.Inspection">
      <nav-route exact name="checklist-index">{{ Naming.Checklists }}</nav-route>
      <nav-route exact name="check-index">{{ Naming.Checks }}</nav-route>
    </nav-group>

    <nav-group icon="compress" title="Administration">
      <nav-route v-if="$root.hasAbility('manage-job-types', 'Billow\\Models\\Company')" exact name="job-type-index">{{ Naming.JobTypes }}</nav-route>
      <nav-route v-if="$root.hasAbility('manage-asset-types', 'Billow\\Models\\Company')" exact name="asset-type-index">{{ Naming.AssetTypes }}</nav-route>
      <nav-route v-if="$root.hasAbility('manage-asset-groups', 'Billow\\Models\\Company')" exact name="asset-group-index">{{ Naming.AssetGroups }}</nav-route>
      <nav-route v-if="$root.hasAbility('view-custom-field-templates', 'App\\Models\\CustomFieldTemplate')" exact name="custom-field-templates">{{ Naming.CustomFields }}</nav-route>
      <nav-route v-if="$root.hasAbility('view-users', 'App\\User')" exact name="user-index">{{ Naming.Users }}</nav-route>
      <nav-route exact name="company-overview">{{ Naming.Company }}</nav-route>
    </nav-group>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'
import NavRoute from '../NavRoute'
import NavGroup from '../NavGroup'

export default {
  components: {
    NavRoute,
    NavGroup,
  },

  computed: mapGetters('auth', ['user'])
}
</script>
