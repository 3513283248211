import { Line } from 'vue-chartjs'

export default {

  extends: Line,

  props: {
    data: Object,
    options: Object,
    responsive: {
      type: Boolean,
      default: true,
    },
    maintainAspect: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      maintainAspectRatio: this.maintainAspect
    }
  },

  mounted() {
    this.render()
  },

  methods: {
    render() {
      let { data, options } = this
      this.renderChart(data, {
        ...options,
        responsive: this.responsive,
        maintainAspectRatio: this.maintainAspect,
      })
    }
  }

}
