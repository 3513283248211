import Vue from 'vue'

Vue.component('iot-value-ignition', () => import('./Ignition.vue'))
Vue.component('iot-value-brake-test-state', () => import('./BrakeTestState.vue'))
Vue.component('iot-value-battery-voltage', () => import('./BatteryVoltage.vue'))
Vue.component('iot-value-unit-powered', () => import('./UnitPowered.vue'))
Vue.component('iot-value-device-temperature', () => import('./DeviceTemperature.vue'))
Vue.component('iot-value-device-humidity', () => import('./DeviceHumidity.vue'))
Vue.component('iot-value-iot-device-powered-by-asset-l-1', () => import('./IotDevicePoweredByAssetL1.vue'))
Vue.component('iot-value-machine-ignition-on', () => import('./MachineIgnitionOn.vue'))
Vue.component('iot-value-machine-overload', () => import('./MachineOverload.vue'))
Vue.component('iot-value-machine-fault', () => import('./MachineFault.vue'))
Vue.component('iot-value-run-time', () => import('./RunTime.vue'))
Vue.component('iot-value-380-v-220-v-power-supply', () => import('./380V220VPowerSupply.vue'))
Vue.component('iot-value-fire-signal', () => import('./FireSignal.vue'))
Vue.component('iot-value-24-v-dc-psu', () => import('./24VDCPSU.vue'))
