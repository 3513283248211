import { Pie } from 'vue-chartjs'

export default {

  extends: Pie,

  props: [
    'data',
    'options'
  ],

  mounted() {
    let { data, options } = this
    this.renderChart(data, {
      ...options,
      responsive: true,
      maintainAspectRatio: true,
    })
  }

}
