<template>
<div>
  <div class="status-bubble" :class="status"></div>  
</div>  
</template>

<script>
import moment from 'moment'

export default {
  
  props: {
    robotId: Number,
    asset: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    last_checkin: '',
    status: 'missing'
  }),

  mounted() {
    this.timer = setInterval(() => {
      this.pollMonitoredAssetCheckins()
    }, 5000)
  },
  
  beforeDestroy() {
    clearInterval(this.timer)
  },

  methods: {
    pollMonitoredAssetCheckins() {
      this.$store.dispatch('asset/loadLastCheckin', this.robotId).then(checkIn => {
        this.last_checkin = checkIn ? checkIn.created_at : ''
        if(this.last_checkin) {
          this.setStatus()
        }

      })
    },

    setStatus() {
      var now = moment()
      var last_checkin = moment(this.last_checkin)
      let seconds = now.diff(last_checkin, 'seconds')

      if(seconds <= 10) {
        this.status = 'online'
        return 
      }

      if(seconds > 10 && seconds <= 20) {
        this.status = 'missing'
        return 
      }
      
      if(seconds > 20) {
        this.status = 'dead'
        return 
      }
      
      
    }
  }
}
</script>
<style lang="scss">
.status-bubble {
  height: 15px;
  width: 15px;
  border-radius: 10px;

  &.online {
    background: green;
  }
  
  &.missing {
    background: orange;
  }
  
  &.dead {
    background: red;
  }
}
</style>
