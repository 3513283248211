<template>
<span class="no-wrap">
    <span v-if="inspection.synced_at && !inspection.completed_at">In Progress</span>
    <span v-if="!inspection.synced_at && !inspection.completed_at">Awaiting Sync</span>
    <span v-if="inspection.synced_at && inspection.completed_at">
      {{ inspection.completed_at | date('L LTS') }}
    </span>
</span>  
</template>
<script>
import Inspection from '@/models/Inspection'

export default {
  props: {
    inspection: {
      type: Object,
      default: () => (new Inspection)
    }
  }
}
</script>