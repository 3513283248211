export let props = {
    make: (name, type = [String, Boolean], defaultValue = undefined, required = false) => ({
      [name]: { type, default: defaultValue, required }
    }),
  
    group: mixed => ({ props: { ...mixed } })
  }
  
  export let computed = {
    make: mixed => ({ computed: { ...mixed } })
  }
  
  export let methods = {
    make: mixed => ({ methods: { ...mixed } })
  }
  