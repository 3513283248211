<template>
  <flickity class="scroll-container" :options="flickityOptions">
    <div class="carousel-cell" v-for="(jobs, date) in jobs" :key="date">
      <div class="box upcoming-jobs-by-day" :class="{ 'is-today': isToday(date) }">
        <p class="date">
          <span class="flex-grow is-today" v-if="isToday(date)">Today</span>
          <span v-else class="flex-grow"><span class="day">{{ date | date('DD') }}</span> {{ date | date('MMM') }}</span>
          <span class="count">{{ jobs.length }}</span>
        </p>
        <div class="inner-scroll-container">
        <div class="upcoming-job is-flex align-center" v-for="job in jobs.slice(0, 6)" :key="job.id">
          <span class="has-tooltip-right has-text-grey" :data-tooltip="job.type.description">
            <link-job-handler :job="job">
              {{ `#${job.number} - ${job.site.name}` }}
            </link-job-handler>
          </span>
          <span class="is-flex align-center">
            <link-user-overview-left avatar :user="job.technician"/>
          </span>
        </div>
        </div>
      </div>
    </div>
    <div v-if="ghostFills.length">
      <div class="carousel-cell" v-for="(fill, index) in ghostFills" :key="index">
        <div class="ghost-cell">
          <!-- <icon icon="calendar-alt 3x" type="far"/> -->
        </div>
      </div>
    </div>
  </flickity>
</template>

<script>
import { range } from 'lodash'

export default {

  props: {
    jobs: Array|Object
  },

  data: () => ({
    flickityOptions: {
      prevNextButtons: true,
      pageDots: false,
      cellAlign: 'left',
      contain: true
    }
  }),

  methods: {
    isToday(date) {
      return moment(date).isSame(moment(), 'day')
    }
  },

  computed: {
    ghostFills() {
     let days = Object.keys(this.jobs)
     let daysToFill = 3 - days.length

     if(daysToFill > 0) {
       return range(daysToFill)
     } 
     return 0
    }
  }

}
</script>
