<template>
  <div>
    <div class="is-flex align-center">
      <icon icon="tachometer-alt" class="has-text-grey tooltip"
        :data-tooltip="`The run time as at ${reading.read_at}`"/>
      {{ value }} km
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reading: {
      type: Object,
      required: true,
    },
    variableName: {
      type: String,
      required: true,
    },
  },
  computed: {
    value() {
      return this.reading.data[this.variableName].toLocaleString()
    },
  },
}
</script>

<style scoped>

</style>