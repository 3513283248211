<template>
  <div class="iot-widget-wrapper">
    <h5 class="title is-4 is-flex align-center has-text-weight-light">
      <icon class="mr has-text-grey" icon="tachometer-alt"/>
      <span>Brake Test</span>
    </h5>

    <div class="columns align-center">
      <div class="column">
        <span class="is-flex align-center" v-if="isPassed">
          <icon class="has-text-success iginition-status-icon" icon="circle"/>
          <span class="has-text-weight-bold">
            Passed
            <br>
            <span
              :data-tooltip="`The last brake test passed at ${latestReading.read_at}`"
              class="is-size-7 has-text-grey tooltip"
            >{{ latestReading.read_at | date('L LTS') }}</span>
          </span>
        </span>
        <span class="is-flex align-center" v-else-if="isFailed">
          <icon class="has-text-danger iginition-status-icon" icon="circle"/>
          <span class="has-text-weight-bold">
            Failed
            <br>
            <span
              :data-tooltip="`The last brake test failed at ${latestReading.read_at}`"
              class="is-size-7 has-text-grey tooltip"
            >{{ latestReading.read_at | date('L LTS') }}</span>
          </span>
        </span>
        <span class="is-flex align-center" v-else-if="isPending">
          <icon class="has-text-warning iginition-status-icon" icon="exclamation-triangle"/>
          <span class="has-text-weight-bold">
            Pending
            <br>
            <span
              :data-tooltip="`Last test conducted: ${lastTestConducted ? lastTestConducted.read_at : 'More than 24 hours ago'}`"
              class="is-size-7 has-text-grey tooltip"
            >{{ latestReading.read_at | date('L LTS') }}</span>
          </span>
        </span>
      </div>
      <div class="column has-text-right">
        <span class="is-size-7" v-if="isPassed">
          This asset has passed <b>{{ passedTestsCount }}/{{
            totalTestsCount
          }}</b> brake tests in the past <b>24 hours</b>.
        </span>
        <span class="is-size-7" v-else-if="isFailed">
          This asset has failed <b>{{ failedTestsCount }}/{{
            totalTestsCount
          }}</b> brake tests in the past <b>24 hours</b>.
        </span>
        <span class="is-size-7" v-else-if="isPending">
          This asset has missed its scheduled test window.
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { filter, first, forEach, reverse, sortBy } from 'lodash'

export default {
  props: {
    latestReading: {
      type: Object,
      required: true,
    },
    oldestReading: {
      type: Object,
      required: true,
    },
    last24HourReadings: {
      type: Array,
      default: [],
      required: true,
    },
    variableName: {
      type: String,
      required: true,
    },
  },

  computed: {
    lastTestConducted() {
      let lastTestConducted = filter(this.last24HourReadings,
        reading => reading.data[this.variableName] === 'passed' || reading.data[this.variableName] === 'failed')

      if (lastTestConducted.length === 0) {
        return null
      }

      lastTestConducted = sortBy(lastTestConducted, 'read_at')
      lastTestConducted = reverse(lastTestConducted)
      lastTestConducted = first(lastTestConducted)
      return lastTestConducted
    },
    isPassed() {
      return this.latestReading.data[this.variableName] === 'passed'
    },
    isFailed() {
      return this.latestReading.data[this.variableName] === 'failed'
    },
    isPending() {
      return this.latestReading.data[this.variableName] === 'pending'
    },
    passedTestsCount() {
      return filter(this.last24HourReadings, reading => reading.data[this.variableName] === 'passed').length
    },
    failedTestsCount() {
      return filter(this.last24HourReadings, reading => reading.data[this.variableName] === 'failed').length
    },
    totalTestsCount() {
      return this.last24HourReadings.length
    },
    powerCycles() {
      let count = 0

      let lastReading = null
      forEach(this.last24HourReadings, reading => {
        if (reading.data[this.variableName] !== lastReading) {
          count++
        }

        lastReading = reading.data[this.variableName]
      })

      return count
    },
  },

}
</script>