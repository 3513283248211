<template>
  <div>
    <div class="title">
      <div class="columns">
        <div class="column">
          Event History
        </div>
        <div class="column is-3-desktop">
          <date-picker
            class="is-marginless"
            placeholder="Filter by date (range)"
            mode="range"
            left-icon="calendar"
            :label="false"
            ref="startDate"
            :value="filters.start_date"
            @input="filterStartDate">
            <action-button
              v-if="filters.start_date"
              slot="right"
              @click="clearFilter('start_date')">
              <icon icon="times"/>
            </action-button>
          </date-picker>
        </div>
      </div>
    </div>

    <pager
      v-if="readings.pagination.to > 1"
      jump-controls
      class="mb-1"
      button-class="is-cool-blue-darker is-rounded"
      details-class="is-rounded"
      :pageable="readings.pagination"
      @nav="goToPage"
      context="events"
    />

    <div class="box">
      <table class="table is-fullwidth">
        <thead>
        <tr>
          <th>Read At</th>
          <th v-for="variableName in formattedVariableNames">{{ variableName }}</th>
        </tr>
        </thead>
        <tr v-for="reading in readings.pagination.data">
          <td>{{ reading.read_at }}</td>
          <td v-for="variableName in variableNames">

            <component
              :is="`iot-value-${kebabCase(variableName)}`"
              :reading="reading"
              :variable-name="variableName"
            />

          </td>
        </tr>
      </table>
    </div>

    <pager
      v-if="readings.pagination.to > 1"
      jump-controls
      class="mb-1"
      button-class="is-cool-blue-darker is-rounded"
      details-class="is-rounded"
      :pageable="readings.pagination"
      @nav="goToPage"
      context="events"
    />

  </div>
</template>

<script>
import { debounce, kebabCase } from 'lodash'

export default {
  props: {
    readings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      going_to_page: false,
      filters: {
        start_date: '',
      },
    }
  },
  methods: {
    goToPage(path) {
      this.$router.push(path)
    },
    kebabCase(value) {
      return kebabCase(value)
    },
    runFilters: debounce(function () {
      if (this.going_to_page) return
      this.loading = true
      this.$router.push({
        name: 'asset-sensor-dashboard',
        query: this.filters,
      })
    }, 500),
    filterStartDate(range) {
      if (this.going_to_page) return
      if (!range || range.indexOf(' to ') === -1) return
      this.filters.start_date = range
      this.$refs.startDate.picker.redraw()
      this.runFilters()
      console.log('filtering')
    },
    clearFilter(filter) {
      this.filters[filter] = null
      this.runFilters()
    },
  },
  computed: {
    variableNames() {
      return Object.keys(_.first(this.readings.pagination.data).data)
    },
    formattedVariableNames() {
      return _.map(this.variableNames, variableName => _.startCase(variableName))
    },
  },
  created() {
    this.filters = { ...this.$route.query }
  },
}
</script>

<style scoped>

</style>