<template>
  <div v-if="typeof value === 'boolean'">
    <div v-show="value">
      <icon icon="circle" class="has-text-success tooltip"
        :data-tooltip="`The ignition was switched ON at ${reading.read_at}`"/>
      Vehicle in Use
    </div>
    <div v-show="!value">
      <icon icon="power-off" class="has-text-danger tooltip"
        :data-tooltip="`The ignition was switched OFF at ${reading.read_at}`"/>
      Vehicle not in use
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reading: {
      type: Object,
      required: true,
    },
    variableName: {
      type: String,
      required: true,
    },
  },
  computed: {
    value() {
      return this.reading.data[this.variableName]
    },
  },
}
</script>

<style scoped>

</style>